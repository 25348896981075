import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Footer from "components/Footer/Footer.js";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";

import { makeStyles } from "@material-ui/core/styles";

import colorDescriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/colorDescriptionStyle.js";

import imageScanneQRCODE from "assets/img/images/imageScanneQR.png";


const useStyles = makeStyles(colorDescriptionStyle);

export default function SectionFooter() {
  const classes = useStyles();
  return (
    <div className={" "}>
      {/* <div className={classes.container}> */}
      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.left}>
              {/* <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="http://blog.creative-tim.com/?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classes.block}
                  >
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classes.block}
                  >
                    Presentation
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="#pablito"
                    onClick={e => e.preventDefault()}
                    className={classes.block}
                  >
                    Discover
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="#pablito"
                    onClick={e => e.preventDefault()}
                    className={classes.block}
                  >
                    Payment
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/contact-us?ref=mkpr-e-ecommerce"
                    target="_blank"
                    className={classes.block}
                  >
                    Contact us
                  </a>
                </ListItem>
              </List> */}
            </div>
            <div className={classes.right}>
              Copyright ProMyTrade &copy; {1900 + new Date().getYear()}{" "}
              {/* <a
                href="https://www.creative-tim.com?ref=mkpr-e-ecommerce"
                target="_blank"
                className={classes.aClasses}
              > */}
                
              {/* </a>{" "} */}

            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            {/* <h5>Payment</h5>
            <p><i className="far fa-credit-card"></i> Credit Card</p>
                             <p><i className="fab fa-cc-mastercard"></i> Mastercard</p>
                             <p><i className="fab fa-cc-visa"></i> Visa</p>
                             <p><i className="fab fa-paypal"></i> Paypal</p>
                             <p><i className="fas fa-university"></i> Transfer Bank</p> */}
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Mentions Légales</h5>
            <div className={classes.socialFeed}>
              <div>
              <a href="https://multibankfx.com/files/pdf/MEXAtlantic_PrivacyPolicy_14062023.pdf"
              target="_blank"
                    // onClick={e => e.preventDefault()}
                    className={classes.block}
                  >
                    Politique de confidentialité
                  </a>
                </div>
                <br />
              <div>
              <a href="https://multibankfx.com/files/pdf/AGR_MEXATL_AE_ClientAgreement_v10_15062023.pdf"
              target="_blank"  className={classes.block}>Termes et conditions
                  </a>                
              </div>
              <br />
              <div>
              <a href="redirect-mailto.php"
              target="_blank"  className={classes.block}>Contact
                  </a>             
              </div>
              <br />
              <div>
              <a href="https://youtu.be/eAUG28aADtY"
              target="_blank"  className={classes.block}>Youtube ProMyTrade
                  </a>             
              </div>
            </div>
            
          </GridItem>
          
        </GridContainer>
      </Footer>
    </div>
  );
}
